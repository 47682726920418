th.sortable {
    color: #2196F3;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: 95% 50%;
    background-size: 16px;
}
.as-react-table .input-group-text:empty{
    display: none;
}
.asrt-page-length{
    display: inline-block;
}
.asrt-page-length .input-group-addon{
    display: inline-block;
    width: auto;
    margin: 0;
    padding: 0;
    background: #fff;
    border: none;
}
.asrt-pagination {
    margin: 0;
}
.asrt-td-loading {
    background-color: #fff;
}
.asrt-loading-textwrap{
    padding: 5px 0px;
}